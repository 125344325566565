import React, { Component } from "react";
import "./App.css";
import Groceries from "./Groceries";

import { Switch, Route, Redirect, Link } from "react-router-dom";

class App extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={Forward} />
        <Route path="/groceries" component={Groceries} />
        <Route component={NotFound} />
      </Switch>
    );
  }
}

export default App;

const Forward = () => <Redirect to="/groceries" />;

const NotFound = () => (
  <div className="tc flex flex-column justify-center items-center vh-100">
    <div>
      <h1 className="bb pb3 mb0">Sorry</h1>
      <h2>There's nothing here</h2>
    </div>
    <Link to="/">Back to front page</Link>
  </div>
);
