import React, { Component } from "react";
import heroImg2 from "./img/2-clay-phones.png";
import logo from "./img/logo-groceries.png";
import apptore from "./img/appstore.svg";

class Groceries extends Component {
  render() {
    return (
      <div
        className="tc center items-center flex-column "
        style={
          {
            //overflowX: "hidden"
          }
        }
      >
        <div className="tc center flex-column pt0">
          <img src={logo} className="w-50 mw6-ns mw5 mt3" />
          <h2 className="fw5 mb4 mh3 mt0 lh-title i">
            A simple and smart shopping list app
          </h2>

          <div className="flex justify-left flex-wrap mw9 center">
            <div className="tl ph4 mb4 flex-40-ns">
              <div className="ttu tracked b o-70 pb1 bb b--black-20">
                What's special?
              </div>
              <ul className="list f4  ml3 customlist bulletlist">
                <li>Automatically repeats items that you buy frequently</li>
                <li>Smart item order based on your shopping habits</li>
                <li>Share your list and sync in real-time</li>
                <li>Quickly search and add items</li>
              </ul>
              <div className="pt2 center pl3 ml2">
                <a href="https://itunes.apple.com/us/app/groceries-simple-and-smart/id1253385822">
                  <img src={apptore} className="w-50 mw4-ns" id="appStoreBtn" />
                </a>
              </div>
            </div>
            <div className="tl ph4 mb4 order-2 order-1-ns flex-40-ns">
              <div className="mt3 ttu b tracked o-70 pb1 bb b--black-20">
                What users say
              </div>
              <ul className="list f4 i flex flex-column items-start customlist quotelist">
                <li>Phenomenal idea with simple UX</li>
                <li>I have been looking all over for an app like this</li>
                <li>The design is sick! Feels very polished and finished</li>
                <li>Excellent idea!</li>
                <li>Love the design</li>
              </ul>
            </div>
            <div className="overflow-hidden overflow-visible-ns flex items-start justify-center relative flex-60-ns">
              <img
                src={heroImg2}
                className="absolute-ns ml4"
                style={{
                  width: "140%",
                  maxWidth: "800px"
                }}
              />
            </div>
            <div className="pa4 tl order-3 w-100">
              &copy; Heikki Mantere 2018
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Groceries;
